import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Input, Button, useToast } from '@chakra-ui/react';
import axios from 'axios';

function AdminActivityLog() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const toast = useToast();

  const fetchLogs = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/api/admin/getAdminActivityLogs`;
      if (startDate && endDate) {
        url += `?startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59`;
      }

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.success) {
        setLogs(response.data.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Only super admin allowed here');
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch admin logs.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <ChakraProvider>
      <Box p={4}>
        {error ? (
          <Box>{error}</Box>
        ) : (
          <>
            <Box fontSize="2xl" fontWeight="bold" mb={4}>Admin Activity Logs</Box>

            {/* Date Filters */}
            <Box display="flex" gap={4} mb={4}>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
              />
              <Button colorScheme="blue" onClick={fetchLogs}>Filter</Button>
            </Box>

            {loading ? (
              <Spinner />
            ) : (
              <Box overflowX="auto" maxHeight="600px" overflowY="scroll" border="1px solid #ddd" borderRadius="8px">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Admin User</Th>
                      <Th>Action Type</Th>
                      <Th>Details</Th>
                      <Th>Timestamp</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {logs.map((log, index) => (
                      <Tr key={log.actionId}>
                        <Td>{index + 1}</Td>
                        <Td>{log.adminUsername}</Td>
                        <Td>{log.actionType}</Td>
                        <Td>{log.details}</Td>
                        <Td>{new Date(log.actionTimestamp).toLocaleString()}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </>
        )}
      </Box>
    </ChakraProvider>
  );
}

export default AdminActivityLog;
