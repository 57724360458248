import React, { useState, useEffect } from "react";
import {
    ChakraProvider,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useToast,
    Spinner,
    Stack,
} from "@chakra-ui/react";
import axios from "axios";

function RevenueShare() {
    const [nftStakers, setNftStakers] = useState({
        season1: 0,
        season2: 0,
    });
    const [loading, setLoading] = useState(true);
    const [revenueData, setRevenueData] = useState({
        season1Amount: "",
        season2Amount: "",
    });
    const toast = useToast();

    useEffect(() => {
        const fetchNftStakers = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/admin/api/rewards/revenue-share/stakers`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                );

                if (response.data.success) {
                    setNftStakers(response.data.data);
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to fetch NFT stakers.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description:
                        error.response?.data?.message ||
                        "Error fetching NFT stakers.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchNftStakers();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRevenueData({ ...revenueData, [name]: value });
    };

    const handleSubmit = async () => {
        const { season1Amount, season2Amount } = revenueData;

        if (!season1Amount || !season2Amount) {
            toast({
                title: "Validation Error",
                description: "Both Season 1 and Season 2 amounts are required.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (isNaN(season1Amount) || isNaN(season2Amount)) {
            toast({
                title: "Validation Error",
                description: "Amounts must be valid numbers.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/api/rewards/revenue-share`,
                {
                    season1Amount: parseFloat(season1Amount),
                    season2Amount: parseFloat(season2Amount),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );

            if (response.data.success) {
                toast({
                    title: "Success",
                    description: "Revenue share rewards created successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description:
                    error.response?.data?.message ||
                    "Failed to submit revenue share data.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <ChakraProvider>
            <Box p={6}>
                <Stack spacing={4} direction="row">
                    <FormControl mb={4}>
                        <FormLabel>Season 1 Stakers Count</FormLabel>
                        <Input
                            value={nftStakers.season1}
                            type="number"
                            readOnly={true}
                        />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Season 1 Amount</FormLabel>
                        <Input
                            placeholder="Enter amount for Season 1"
                            name="season1Amount"
                            value={revenueData.season1Amount}
                            onChange={handleInputChange}
                            type="number"
                        />
                    </FormControl>
                </Stack>

                <Stack spacing={4} direction="row">
                    <FormControl mb={4}>
                        <FormLabel>Season 2 Stakers Count</FormLabel>
                        <Input
                            value={nftStakers.season2}
                            type="number"
                            readOnly={true}
                        />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Season 2 Amount</FormLabel>
                        <Input
                            placeholder="Enter amount for Season 2"
                            name="season2Amount"
                            value={revenueData.season2Amount}
                            onChange={handleInputChange}
                            type="number"
                        />
                    </FormControl>
                </Stack>
                <Button colorScheme="blue" mt={4} onClick={handleSubmit}>
                    Submit
                </Button>
            </Box>
        </ChakraProvider>
    );
}

export default RevenueShare;