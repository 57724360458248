import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Button, Table, Thead, Tbody, Tr, Th, Td, Spinner, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';

function InternalAdmin() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newAdmin, setNewAdmin] = useState({ username: '', email: '', password: '' });
  const [resetAdmin, setResetAdmin] = useState(null);
  const [resetPassword, setResetPassword] = useState('');
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();

  const toast = useToast();

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/accounts`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.success) {
        setAdmins(response.data.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Only super admin allowed here');
      } else {
        console.error('Error fetching admins:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const createAdmin = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/createAccount`, newAdmin, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.success) {
        fetchAdmins(); // Refresh admin list
        onClose();
        setNewAdmin({ username: '', email: '', password: '' });
        toast({
            title: 'Admin Created',
            description: 'A new admin account has been created.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
      } else {
        console.error('Error creating admin:', response.data.error);
      }
    } catch (error) {
      console.error('Error creating admin:', error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/resetPasswordForAdmin/${resetAdmin.adminId}`, {
        password: resetPassword
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.success) {
        onResetClose();
        setResetPassword('');
        toast({
            title: 'Password Reset',
            description: `Password reset for ${resetAdmin.username}.`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
      } else {
        console.error('Error resetting password:', response.data.error);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <ChakraProvider>
      <Box p={4}>
        {error ? (
          <Box>{error}</Box>
        ) : (
          <>
            <Box mb={4}>
              <Button onClick={onOpen} colorScheme="blue">Create New Admin</Button>
            </Box>

            {loading ? (
              <Spinner />
            ) : (
              <Table variant="simple" mb={4}>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Username</Th>
                    <Th>Email</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {admins.map((admin, index) => (
                    <Tr key={admin.adminId}>
                      <Td>{index + 1}</Td>
                      <Td>{admin.username}</Td>
                      <Td>{admin.email}</Td>
                      <Td>
                        {index !== 0 && (
                          <Button size="sm" colorScheme="red" onClick={() => { setResetAdmin(admin); onResetOpen(); }}>
                            Reset Password
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Admin</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="Username"
                mb={3}
                value={newAdmin.username}
                onChange={(e) => setNewAdmin({ ...newAdmin, username: e.target.value })}
              />
              <Input
                placeholder="Email"
                mb={3}
                value={newAdmin.email}
                onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })}
              />
              <Input
                placeholder="Password"
                type="password"
                value={newAdmin.password}
                onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={createAdmin}>
                Create
              </Button>
              <Button variant="ghost" onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isResetOpen} onClose={onResetClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reset password for {resetAdmin?.username}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="New Password"
                type="password"
                value={resetPassword}
                onChange={(e) => setResetPassword(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleResetPassword}>
                Reset Password
              </Button>
              <Button variant="ghost" onClick={onResetClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
}

export default InternalAdmin;
