import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Button, Table, Thead, Tbody, Tr, Th, Td, Spinner } from '@chakra-ui/react';
import axios from 'axios';

function WithdrawalsPage() {
  const [withdrawals, setWithdrawals] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchWithdrawals = async () => {
    setLoading(true);
    try { 
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/withdrawalHistory?page=${page}&limit=50`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
    });
    if (response.data.success) {
        setWithdrawals((prev) => [...prev, ...response.data.data]);
        setTotal(response.data.total);
    }
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, [page]);

  const loadMore = () => {
    if (withdrawals.length < total) {
      setPage((prev) => prev + 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <ChakraProvider>
      <Box p={4}>
        <Table variant="simple" mb={4}>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Username</Th>
              <Th>Asset</Th>
              <Th>Deposit Address</Th>
              <Th>Amount</Th>
              <Th>Withdrawal Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdrawals.map((withdrawal, index) => (
              <Tr key={withdrawal.withdrawId}>
                <Td>{index + 1}</Td>
                <Td>{withdrawal.username}</Td>
                <Td>{withdrawal.assetId}</Td>
                <Td>{withdrawal.depositAddress}</Td>
                <Td>{withdrawal.amount}</Td>
                <Td>{formatDate(withdrawal.withdrawTime)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {loading ? (
          <Spinner />
        ) : withdrawals.length < total ? (
          <Button onClick={loadMore}>Load More</Button>
        ) : (
          <Box>No more withdrawals</Box>
        )}
      </Box>
    </ChakraProvider>
  );
}

export default WithdrawalsPage;
